import React, { useRef } from 'react';
import { getDomains, postRun, getDownloadFile } from '../Api';
import { useState, useEffect, useCallback } from 'react';

import { Flex, Box, Spacer , Input, Container,  HStack, Skeleton, useBreakpointValue, 
    Tabs, TabList, TabPanels, Tab, TabPanel, Stack, Card, CardBody, CardHeader,
    Heading, Text, useToast, IconButton, Menu, MenuButton, Tag,
    Button, MenuList, MenuItem, Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel
} from '@chakra-ui/react';


import { RepeatIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { IoIosSend, IoMdDownload } from 'react-icons/io';

import LoadingOverlay from '../components/LoadingOverlayComponent';
import SplitText  from '../components/SplitText';
import { usePageColor } from '../contexts/ColorContext';

const ChatbotPage = ({checkExpireTime}) => {

    const colors = usePageColor('chatbot');

    const toast = useToast();
    const inputRef = useRef(null);

    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);

    const [inputQuery, setInputQuery] = useState("");
    const [postRunTriggered, setPostRunTriggered] = useState(false);

    const [queryResponses, setQueryResponses] = useState('');
    const [contextResponse, setContextRespones] = useState([]);

    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);

    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isAIResponseLoading, setIsAIResponseLoading] = useState(false);

    const topk_list = [1, 2, 3, 4, 5, 6, 7];
    const [topk, setTopk] = useState(1);

    
    const togglePostRunWrapper = () => {
        if (!isHandlingEvent) {
            setIsHandlingEvent(true);
            handlePostRun();
            setTimeout(() => setIsHandlingEvent(false), 500);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                if (data.result === "No Token Sent") {
                    setAllDomainsData({ result: [] });
                } else {
                    setAllDomainsData(data);
                    setActivedDomainIndex(data.result[0]?.domain_id);
                    setInputQuery(data.result.find(domain => domain.domain_id === activedDomainIndex)?.default_prompt_text || "데이터 로딩 중...")
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, [toast, activedDomainIndex]);

    useEffect(()=>{
        setTopk(topk);
    }, [topk])

    const handlePostRun = useCallback(async () => {

        await checkExpireTime();
    
        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } else {
            setIsFormLoading(true);
            
            setQueryResponses(''); // 기존 유사사례 답변 초기화
            setIsQueryResponseShowed(false);
            setIsAIResponseLoading(true);

            try {
                const result = await postRun(inputQuery, topk);
                let results = result.result;

                if (results){
                    setIsQueryResponseShowed(true);                    
                    setIsFormLoading(false);
                    setIsAIResponseLoading(false);
                    setContextRespones(results);
                }
                setPostRunTriggered(true);
            } catch {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
    
            } finally {
                setIsFormLoading(false);
                setIsQueryResponseShowed(true);
                setIsAIResponseLoading(false);
            }
        }
    }, [inputQuery, toast, setPostRunTriggered, topk])

    useEffect(()=>{
        if (postRunTriggered) {
            if (contextResponse.length < 1) {
                console.log("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                setQueryResponses("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
            }
            setPostRunTriggered(false);
        } 
    }, [postRunTriggered, queryResponses, setPostRunTriggered])

    useEffect(()=>{
        setIsQueryResponseShowed(false);
    }, [activedDomainIndex])

    const handleGetFileDownload = useCallback(async (fileId) => {
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, []);

    const overflowTabFixed = useBreakpointValue({ base: false, md: true })
    return(
        <Container bg={colors.ContainerMainBg} maxW='100%' minH='95vh' size='container.3xl'>
            <Container
                maxW = 'container.xl' 
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >       
                    <Flex direction='column'>
                        <Container maxW='' position='relative' pb='10'>                            
                        <LoadingOverlay isLoading={isFormLoading} bgColor={colors.ContainerMainBg}/>
                            {overflowTabFixed && (
                                    <Tag mt='10' bg="green" p="1">
                                            {allDomainsData.result.map((domainData) => (
                                                <Tag 
                                                    bg="green"
                                                    key={domainData.domain_id}
                                                    onClick={() => {
                                                        setActivedDomainIndex(domainData.domain_id);
                                                    }}
                                                    color="white"
                                                    fontWeight="bold"
                                                >
                                                    국민연금공단
                                                </Tag>
                                            ))}
                                    </Tag>
                            )}
                            {!overflowTabFixed && (
                                <Flex mt='7' >                                
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={colors.AssistantMessageBg} w="300" borderRadius='13px'>
                                            {allDomainsData.result[activedDomainIndex-1]?.domain_name || "데이터 로딩 중..."}
                                        </MenuButton>
                                        <MenuList>
                                            {allDomainsData.result.map((domainData)=>(
                                                <MenuItem
                                                    key = {domainData.domain_id}
                                                    onClick={()=>{
                                                        setActivedDomainIndex(domainData.domain_id)
                                                        setInputQuery(domainData.default_prompt_text)
                                                    }}
                                                    >
                                                    {domainData.domain_name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                    <Spacer />
                                    <HStack>                                                                                             
                                    </HStack>                                    
                                </Flex>
                            )}
                            <Container maxW="1300px" boxShadow="md" borderRadius="md" p={4} border="1px solid #E2E8F0">
                                <Box align="center">
                                    <HStack>
                                    <Input
                                        placeholder={"어떤 질문을 하고 싶으신가요? ex) 지급된 연금액에 대해 경과조치 처리가 가능할까요?" || "데이터 로딩 중..."}
                                        borderRadius='13px'
                                        border="none"
                                        fontWeight="bold"
                                        focusBorderColor='transparent'
                                        fontSize="1.3rem" 
                                        flex="1"
                                        onKeyDown={(e) => {
                                            if(e.key === 'Enter')                     
                                            // handlePostRun();    
                                            togglePostRunWrapper();                
                                        }}
                                        ref={inputRef}
                                        onChange={(e) => {setInputQuery(e.target.value);}}
                                        value={inputQuery}
                                    />
                                    <IconButton
                                        borderRadius='13px'
                                        onClick={() => {
                                            // handlePostRun();
                                            togglePostRunWrapper();
                                        }}
                                        icon={<SearchIcon fontSize="1.6rem" />}
                                        variant="ghost"
                                        color="green"
                                        size="lg"
                                    />
                                    <IconButton
                                        icon={<RepeatIcon fontSize="1.6rem" />}
                                        onClick={()=>{
                                            setIsQueryResponseShowed(false);
                                            setActivedDomainIndex(1);
                                            setTopk(1);
                                            setInputQuery(allDomainsData.result[0]?.default_prompt_text || "데이터 로딩 중...")
                                        }}
                                        borderRadius='13px'
                                        mr="2"
                                        size="lg"
                                        variant="ghost"
                                    />
                                    </HStack> 
                                </Box>
                            </Container>
                        </Container>
                        <Spacer/>            
                        <Container alignContent='center' maxW='' mt='10'>
                            <Box>
                                <Tabs colorScheme='green' >
                                    <Flex>
                                    <TabList>
                                        <Tab>유사 사례</Tab>
                                    </TabList>
                                    <Spacer/>
                                    <Menu>
                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  bg="#F0FFF0" w="300" borderRadius='13px'>
                                                사례 검색 건수 : {topk}
                                            </MenuButton>
                                            <MenuList>
                                                {topk_list.map((top_k)=>(
                                                    <MenuItem
                                                        key = {top_k}
                                                        onClick={()=>{
                                                            setTopk(top_k)
                                                        }}
                                                        >
                                                        {top_k}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    </Flex>

                                    <TabPanels mt ='4' borderRadius='13px'>
                                        {isAIResponseLoading && (
                                            <Stack pt='30px'>
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                            </Stack>
                                        )}  
                                        {isQueryResponseShowed && (
                                        <TabPanel>
                                            <Accordion allowToggle>
                                                {contextResponse.map((context, index) => (
                                                    <AccordionItem key={context.file_id} mb={4} boxShadow="lg" borderRadius="13px" backgroundColor="white">
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box display="flex" alignItems="center" flex="1" textAlign="left">
                                                                    {/* 동그라미 번호 */}
                                                                    <Box 
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        width="35px"
                                                                        height="35px"
                                                                        borderRadius="50%"
                                                                        backgroundColor="#F0FFF0"
                                                                        mr={3} // 제목과 간격
                                                                        fontSize="20px"
                                                                    >
                                                                        {index + 1}
                                                                    </Box>
                                                                    <Heading size="md">
                                                                        {context.file_name}
                                                                    </Heading>
                                                                </Box>
                                                                <Spacer />
                                                                <IconButton 
                                                                    icon={<IoMdDownload />}
                                                                    mr="2"
                                                                    onClick={() => handleGetFileDownload(context.file_id)}
                                                                />
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            <Stack>
                                                                <Box mt="3">
                                                                    {/* Question : claimant_argument 줄바꿈 처리 */}
                                                                    <Text><b>Question:</b><SplitText text={context.claimant_argument} /> </Text>
                                                                </Box>
                                                                <Box mt="3">
                                                                    {/* Summary : claimant_argument_summary 줄바꿈 처리 */}
                                                                    <Text><b>Summary:</b><br/><br/><SplitText text={context.claimant_argument_summary} /> </Text>
                                                                </Box>
                                                                <Box mt="3">
                                                                    {/* Answer : judgment 줄바꿈 처리 */}
                                                                    <Text><b>Answer:</b><SplitText text={context.judgment} /> </Text>
                                                                </Box>
                                                                <Accordion allowToggle>
                                                                    <AccordionItem>
                                                                        <h2>
                                                                            <AccordionButton>
                                                                                <Box as="span" flex="1" textAlign="left">
                                                                                    <b>법률 근거</b>
                                                                                </Box>
                                                                                <AccordionIcon />
                                                                            </AccordionButton>
                                                                        </h2>
                                                                        <AccordionPanel pb={4}>
                                                                            {/* related_laws 줄바꿈 처리 */}
                                                                            <Text><SplitText text={context.related_laws} /></Text>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </Accordion>
                                                            </Stack>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </TabPanel>
                                    )}

                                    </TabPanels>                             
                                </Tabs>                                
                            </Box>               
                        </Container>
                        <Spacer/>
                    </Flex>                             
            </Container>                                        
        </Container>
    )
}
export default ChatbotPage;
